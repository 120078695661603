import React, { useState, useEffect } from 'react';
import './Landlords.css';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import { Form } from 'react-bootstrap';


const InputField = ({ label, name, type, handleChange }) => (
  <div className="landlords-input mt-4">
    <label htmlFor={name} className='landlords-form-label'>{label} <span className="text-danger">*</span></label>
    <input type={type} name={name} id={name} onChange={handleChange} className='required-number' />
  </div>
);

const SelectField = ({ label, name, options, handleChange }) => (
  <div className="landlords-input mt-4">
    <label htmlFor={name} className='landlords-form-label'>{label} <span className="text-danger">*</span></label>
    <select name={name} id={name} onChange={handleChange} className='landlords-form-select'>
      <option value="">----select an option----</option>
      {options.map((option, index) => (
        <option key={index} value={option}>{option}</option>
      ))}
    </select>
  </div>
);

export const Landlords = () => {
  const [moreInfo, setMoreInfo] = useState({
    BestTimeToCall: "",
    City: "",
    EmailAddress: "",
    FirstName: "",
    HowManyRental: "",
    LastName: "",
    Phone: "",
    PostalCode: "",
    PropertyCurrently: "",
    PropertyType: "",
    Province: "",
    StreetAddress: "",
    optradio: "",
  });

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMoreInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePostalCode = (postalCode) => {
    // Canadian Postal Code Validation: A1A 1A1 format
    const caRegex = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/;
    return caRegex.test(postalCode);
  };

  const handleValidation = () => {
    let isValid = true;

    if (!moreInfo.FirstName) {
      toast.error("First Name is required.");
      isValid = false;
    }
    if (!moreInfo.LastName) {
      toast.error("Last Name is required.");
      isValid = false;
    }
    if (!moreInfo.EmailAddress) {
      toast.error("Email Address is required.");
      isValid = false;
    } else if (!validateEmail(moreInfo.EmailAddress)) {
      toast.error("Invalid email address.");
      isValid = false;
    }
    if (moreInfo.Phone.length<11) {
      toast.error("Phone number should be atleast 10 digit.");
      isValid = false;
    }
    if (!moreInfo.StreetAddress) {
      toast.error("Street Address is required.");
      isValid = false;
    }
    if (!moreInfo.City) {
      toast.error("City is required.");
      isValid = false;
    }
    if (!moreInfo.Province) {
      toast.error("Province is required.");
      isValid = false;
    }
    if (!moreInfo.PostalCode) {
      toast.error("Postal Code is required.");
      isValid = false;
    } else if (!validatePostalCode(moreInfo.PostalCode)) {
      toast.error("Invalid Canadian postal code.");
      isValid = false;
    }
    if (!moreInfo.PropertyType) {
      toast.error("Property Type is required.");
      isValid = false;
    }
    if (!moreInfo.BestTimeToCall) {
      toast.error("Best Time to Call is required.");
      isValid = false;
    }
    if (!moreInfo.HowManyRental) {
      toast.error("Please select how many rental properties you own.");
      isValid = false;
    }

    if (!isCaptchaVerified) {
      toast.error("Please complete the CAPTCHA.");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async () => {
    if (!handleValidation()) return;

    const sanitizedData = Object.keys(moreInfo).reduce((acc, key) => {
      acc[key] = DOMPurify.sanitize(moreInfo[key]);
      return acc;
    }, {});

    setIsSubmitting(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/mailsend/moreinfo`, sanitizedData);
      if (response.data.status === 200) {
        toast.success('Form submitted successfully! Redirecting...');
        setTimeout(() => {
          window.location.href = '/thankyou';
        }, 2000);
      } else {
        toast.error(response.data.res || 'Failed to submit the form. Please try again.');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.res || 'An unexpected error occurred.';
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePhoneChange = (phone) => {
    const sanitizedPhone = phone.replace(/[^0-9]/g, '');
    setMoreInfo((prev) => ({
      ...prev,
      Phone: sanitizedPhone
    }));
  };

  return (
    <>
      <KWNavbar />
      <ToastContainer />
      <div className="container mt-5 pt-5 mb-5">
        <div className="col-sm-12 col-md-11 mx-auto">
          <div className="landlords-main-head">
            <div className="landlords-cHeader">Property owners learn more about us!</div>
            <div className="landlords-cPara">
              If you are a property owner and are interested in learning more about our property management services please fill out the form below and we will provide you with additional information about our services! You can also read our
              <a href="#">Frequently Asked Questions</a>, or call or email anytime if you have additional questions or comments.
            </div>
            <div className="landlords-cContacth">
              <div className="landlords-cContacth1">Phone: 519-954-8082</div>
              <div className="landlords-cContacth2">Email: <a href="mailto:kwp@kwproperty.com">kwp@kwproperty.com</a></div>
            </div>
          </div>

          <div className="landlord-form-main">
            <div className="landlords-form">
              <InputField label="First Name" name="FirstName" type="text" handleChange={handleChange} />
              <InputField label="Last Name" name="LastName" type="text" handleChange={handleChange} />
              <InputField label="Email Address" name="EmailAddress" type="text" handleChange={handleChange} />
              <div className='landlords-input mt-4'>
                <label className='landlords-form-label'>Phone 
                <span class="text-danger">*</span>
                </label>
                <PhoneInput
                  country={'ca'}
                  value={moreInfo.Phone}
                  onChange={handlePhoneChange}
                  placeholder="+1 (999) (999) (9999)"
                  inputProps={{
                    name: 'Phone',
                    required: true,
                    autoFocus: true,
                  }}
                  inputStyle={{
                    width: '100%',
                  }}
                />
              </div>
              <InputField label="Street Address" name="StreetAddress" type="text" handleChange={handleChange} />
              <InputField label="City" name="City" type="text" handleChange={handleChange} />
              <InputField label="Province" name="Province" type="text" handleChange={handleChange} />
              <InputField label="Postal Code" name="PostalCode" type="text" handleChange={handleChange} />

              <SelectField
                label="Property Type"
                name="PropertyType"
                options={["Apartment", "Commercial", "House", "Townhouse", "Semi-Detached"]}
                handleChange={handleChange}
              />

              <SelectField
                label="Best Time To Call"
                name="BestTimeToCall"
                options={["Anytime", "Morning", "Afternoon", "Evening"]}
                handleChange={handleChange}
              />

              <SelectField
                label="How Many Rental Properties Do You Own?"
                name="HowManyRental"
                options={["1", "2", "3", "4", "5", "More than 5"]}
                handleChange={handleChange}
              />

              <div className="landlords-input mt-4">
                <div className='landlords-form-label2'>Basic property details & any questions/comments <span className="text-danger">*</span></div>
                <textarea onChange={handleChange} name="PropertyCurrently" id="Property-Currently-textarea" className='required-number required-number2 mt-2 Property-Currently-textarea'></textarea>
              </div>

              <div className="landlords-input mt-4">
                <div className='landlords-form-label2'>To protect this website from spam, please check the box below.</div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={() => setIsCaptchaVerified(true)}
                  onExpired={() => setIsCaptchaVerified(false)}

                />
              </div>

              <div className="landlords-input mt-4">
                <button onClick={handleSubmit} type='button' className='Property-Currently-button' disabled={isSubmitting}>
                  {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <KWFooter />
    </>
  );
};
